<template>
  <div class="calendar-subview">
    <List :key="componentKey" :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key"
      :target="list.target" disableCheckbox>
      <template v-slot:statut="{ item }">
        <div class="essai-check">
          <template v-if="item?.type?.uid === 'NOTATION' && item?.date_realisation && states[item.id] === 'REALISE'">
            <SvgIcon name="check" />
          </template>
          <template v-if="item?.type?.uid !== 'NOTATION' && item?.date_realisation">
            <SvgIcon name="check" />
          </template>
        </div>
      </template>

      <template v-slot:designation="{ item }">
        <!-- TODO: JL -->
        <!-- Permet de checker si la notation est issue d'un protocole -->
        <!-- {{ notationsWithProtocolParent.includes(item.id) }} -->
        {{ item.designation ? `${item.designation} (${item.type.designation})` : item.type.designation }}
      </template>

      <template v-slot:previsionnelle="{ item }">
        <template v-if="item.date_previsionnelle">
          {{ helperService.displayDate(item?.date_previsionnelle) }}
        </template>
        <template v-else-if="item?.stade">
          {{ item?.stade?.code_bbch }} - {{
            item.stade.designation_court ?
              item.stade.designation_court :
              item.stade.designation
          }}
        </template>
        <template v-else>
          —
        </template>
      </template>

      <template v-slot:date_realisation="{ item }">
        {{ helperService.displayDate(item?.date_realisation) }}
      </template>

      <template v-slot:etat="{ item }">
        <template v-if="item?.type?.uid === 'NOTATION' && statesFullText[item.id]">
          {{ statesFullText[item.id] }}
        </template>
        <template v-else>
          —
        </template>
      </template>

      <template v-slot:actions="{ item }">
        <Btn v-if="item.observations" icon="info-circle" round grow @click="openModal('viewComment', item)" />
        <ToolDropdown>
          <MenuItem v-if="helperService.userHasPermission('essaievenement_oshow')" :item-data="{
            label: 'Consulter',
            iconName: 'visibility',
            route: {
              name: 'essaiExperimentalTaskChild',
              params: {
                id: essai.id,
                tid: item.id
              },
            }
          }" />
          <MenuItem v-if="item.type.uid === 'NOTATION' && checkNotation(item) && notations[item.id]" :item-data="{
            label: 'Export des donnees',
            iconName: 'share-alt',
          }" @click="downloadFile('xls', 'notation', notations[item.id], 'donnees-consolidees')" />
          <MenuItem v-if="item.type.uid === 'NOTATION' && findNotationWithProtocolParent(item.id)" :item-data="{
            label: 'Fusionner',
            iconName: 'merge',
          }" @click="openModal('mergeNotation', item)" />
        </ToolDropdown>

      </template>
    </List>
  </div>

  <Modal title="Commentaire de la tâche expérimentale" :active="modal.viewComment" :data="modalData"
    @modal-close="modal.viewComment = false">
    <template v-slot:modal-body="{ data }">
      {{ data?.observations }}
    </template>
    <template v-slot:modal-footer>
      <Btn color="primary" text="Fermer" @click="modal.viewComment = false" />
    </template>
  </Modal>

  <!-- Modals mergeNotation -->
  <Modal title="Fusionner la tâche expérimentale" :active="modal.mergeNotation" :data="modalData"
    @modal-close="modal.mergeNotation = false">
    <template v-slot:modal-body="{ data }">
      <Radio v-model="selectedNotationToMerge" required id="typemodalite"
        :items="notationsToMerge.filter((notation) => notation.value !== data.id)"
        :label="`Sélectionner la notation à fusionner avec ${data.designation}`" />
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.mergeNotation = false" />
      <Btn text="Fusionner" @click="mergeNotation(data)" icon="merge" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Modal from '@/components/layout/Modal.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import Radio from '@/components/form/Radio.vue'

export default {
  name: 'CalendarSubview',

  components: {
    Radio,
    SvgIcon,
    Modal,
    MenuItem,
    ToolDropdown,
    List,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  emits: ['updateEssais'],

  data() {
    return {
      states: [],
      statesFullText: [],
      notations: [],
      list: {
        target: {
          route: {
            name: 'essaiExperimentalTaskChild',
            paramsFromRoute: { id: this.$route.params.id },
            itemParams: { tid: 'id' },
          },
        },
        api: {
          endpoint: `essai/${this.$route.params.id}/evenement`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: 0,
            filters: 'pevent:nn()',
          },
        },
        headers: [
          { label: 'Statut' },
          { label: 'Tâche expérimentale', isLink: true },
          { label: 'Prévisionnel' },
          { label: 'Réalisation' },
          { label: 'État d\'avancement' },
        ],
        col: ['statut', 'designation', 'previsionnelle', 'date_realisation', 'etat'],
        key: 'id',
      },

      modalData: {},
      modal: {
        viewComment: false,
        mergeNotation: false,
      },
      componentKey: 0,
      form: {
        experimentalTask: {},
      },
      realizationDate: new Date().toISOString().split('T')[0],
      event_types: [],
      notationsToMerge: [],
      selectedNotationToMerge: null,
      notationsWithProtocolParent: [],
      stade: {
        apiParams: {
          sort: 'ordre.ASC',
        },
      },
    }
  },

  watch: {
  },

  mounted() {
    this.getEventTypes()
  },

  methods: {
    /** ***********************************************
       * Modal
       ********************************************** */
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    closeOpenModal(toClose, toOpen, data) {
      this.modal[toClose] = false
      this.modalData = data
      this.modal[toOpen] = true
    },

    /** ***********************************************
       * Getters
       ********************************************** */

    getProtocol() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocoleId = response.data.protocole.id
        this.fetchService.get(`protocole/${this.protocoleId}`).then((protocole) => {
          const cultures = protocole.data.cultures.map((item) => item.uid)
          if (cultures.length > 0) {
            this.stade.apiParams = {
              sort: 'ordre.ASC',
              filters: `culture.uid:in(${cultures})`,
            }
          }
        })
      })
    },
    /**
       * Type d'événement
       */
    getEventTypes() {
      this.fetchService.get('dictionnaire/type/evenement?sort=designation.ASC').then(
        (response) => {
          const datas = response.data
          this.event_types = datas.filter((et) => et.uid !== 'TRAITEMENTFERTI' && et.uid !== 'TRAITEMENTPHYTO')
        },
      )
    },

    async checkNotation(event) {
      const { params } = this.$route
      const not = await this.fetchService.get(
        `essai/${params.id}/evenement/${event.id}/fiche/notation`,
      )

      let notation = await not.data.pop()

      const res = await this.fetchService.get(
        `essai/${params.id}/evenement/${event.id}/fiche/notation/${notation.id}/reservation?sort=id.DESC`,
      )

      let reservation = await res.data.shift()

      if (this.notationService.getItem('reservations', reservation?.id)) {
        reservation = this.notationService.getItem('reservations', reservation?.id)
        notation = reservation?.notation
      }

      this.states[event.id] = reservation?.etat?.uid || 'ENPREPARATION'
      this.statesFullText[event.id] = reservation?.etat?.designation || 'En préparation'

      if (reservation && reservation?.etat?.uid === 'REALISE') {
        this.notations[event.id] = notation.id
        this.statesFullText[event.id] = 'Réalisé'
        return true
      }

      if (reservation?.etat?.uid === 'PARTIELLEMENTREALISE' || reservation?.etat?.uid === 'ENCOURS') {
        this.statesFullText[event.id] = `${reservation?.etat?.designation} (${notation.progression}%)`
      }

      return false
    },
    /**
       * Rempli le tableau de notations qui peuvent être fusionnées
       * @param notation
       */
    setNotationsToMerge(notation) {
      if (!this.notationsToMerge.find((n) => n.value === notation.id)) {
        this.notationsToMerge.push({
          label: notation.designation ?? '',
          value: notation.id,
        })
      }
    },
    /**
       * Permet de fusionner deux notations ensemble
       * @param item Événement
       */
    mergeNotation(item) {
      /**
         * TODO: JL
         * @see http://gitlab.diatem.net/outils/api-oss/-/issues/294
         */
      this.emitter.emit('open-loader')
      const ficheId = this.findNotationWithProtocolParent(item.id).fiche.id
      this.fetchService.patch(`essai/${item.essai.id}/evenement/${item.id}/fiche/notation/${ficheId}/fusion/${this.selectedNotationToMerge}`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Les notations ont bien été fusionnées.',
          })
          this.emitter.emit('close-loader')
        })
      this.modal.mergeNotation = false
    },

    /** ***********************************************
       * Download
       ********************************************** */
    /**
       * Fichier
       * @param type
       * @param ressource
       * @param rid
       * @param childRessource
       */
    downloadFile(type, ressource, rid, childRessource = null) {
      let endpoint = `taches/type/${type}/ressource/${ressource}/${rid}`

      if (childRessource) {
        endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/${childRessource}`
      }

      const filename = childRessource ? `${ressource}_${rid}_${childRessource}` : `${ressource}_${rid}`

      this.fileService.downloadFile(endpoint, filename, type)
    },
    /** ***********************************************
       * Helpers
       ********************************************** */
    findNotationWithProtocolParent(eventId) {
      return this.notationsWithProtocolParent.find(((n) => n.task && n.task.id === eventId))
    },
  },
}
</script>

<style lang="scss" scoped>
.essai-statut {
  height: 15px;
  width: 15px;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  transform: rotate(45deg);

  &--filled {
    border: 1px solid $color-primary;
    background: $color-primary;
  }
}

.essai-bpe {
  height: 25px;
  width: 25px;
  border: 10px solid $color-primary;
  border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: $font-size-smaller;
  font-weight: $font-weight-lighter;
}

.essai-check {
  height: 40px;
  width: 40px;
  border: 2px solid $color-primary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary;
}
</style>
